import React from 'react'
import { Box, Button, Layout, H4, Text, SEO, FlexCol } from '../components'
import { FontString } from '../views/floorplans/components/fontString'

class ThankYouPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
      path: '',
      navigatePath: '',
      absorbRoute: false,
      showRight: true,
      mounted: false,
    }
  }

  componentDidMount = () => {
    let IE = false
    if (/MSIE 10/i.test(navigator.userAgent)) {
      // This is internet explorer 10
      IE = true
    }

    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent)
    ) {
      // This is internet explorer 9 or 11
      IE = true
    }

    if (/Edge\/\d./i.test(navigator.userAgent)) {
      // This is Microsoft Edge
      IE = true
    }
    let w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    if (IE) {
      w = 0
    }
    if (w < 1024) {
      this.setState({ showRight: false })
    }
    this.setState({ mounted: true })
  }

  componentDidUpdate() {
    if (this.state.showRight) {
      if (
        this.props.location.pathname !== this.state.path &&
        !this.state.absorbRoute
      ) {
        this.setState({
          navigatePath: this.props.location.pathname,
          path: this.props.location.pathname,
        })
        //window.LassoCRM.tracker.track()
      }
      if (
        this.state.absorbRoute &&
        this.props.location.pathname !== this.state.path
      ) {
        this.setState({
          path: this.props.location.pathname,
          absorbRoute: false,
        })
        //window.LassoCRM.tracker.track()
      }
    }
  }

  render() {
    return (
      <Layout>
        <style jsx>{`
          ${FontString};
        `}</style>
        <SEO
          title="Thank you | NUDE by Battistella"
          description="Thank you! We've received your information request and someone will contact you shortly."
          url="thank-you"
        />
        {this.state.mounted && (
          <>
            <Box minHeight="100vh" bg="white">
              <Box
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100vh',
                  userSelect: 'none',
                  pointerEvents: 'none',
                  backgroundImage: `url("${
                    process.env.NODE_ENV !== 'development'
                      ? '/nude/images/hero_bg.jpg'
                      : '/images/hero_bg.jpg'
                  }")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <FlexCol
                position="relative"
                width="100%"
                minHeight="100vh"
                alignItems="center"
                justifyContent="center"
              >
                <FlexCol
                  maxWidth="100ch"
                  minHeight="100%"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  bg="white"
                  style={{ padding: '4rem' }}
                >
                  <H4
                    children={`Thank you! We've received your information request and someone will contact you shortly.`}
                    textAlign="center"
                    mb={3}
                  />
                  <Text
                    children={`Check your email inbox for our Nude by Battistella Lookbook which includes building and community info, floorplans, specs and more! If your email doesn't show up right away, make sure you check your junk folder.`}
                    textAlign="center"
                    mb={2}
                  />
                  <Text
                    children={`In the meantime be sure to check out our instagram and twitter pages. In order to keep upto date on all things Nude & Battistella.`}
                    textAlign="center"
                    mb={6}
                  />
                  <Button
                    children={`Back to the website`}
                    is="a"
                    appearance="reverse"
                    href={
                      process.env.NODE_ENV !== 'development' ? '/nude/' : '/'
                    }
                    width="auto"
                  />
                </FlexCol>
              </FlexCol>
            </Box>
          </>
        )}
      </Layout>
    )
  }
}

export default ThankYouPage
